export class Festival {
    public PKFestivalID: number;
    public FestivalDate: Date;
    public Festival_EN: string;
    public Festival_TE: string;
    public CreatedBy: number;
    public ModifiedBy: number;
    public CreatedDate: Date;
    public ModifiedDate: Date;
    public IsActive: boolean;
}
