import { Component, ViewChild, ElementRef,Input } from '@angular/core';
import { HoroscopeService } from '../horoscope.service';
import { Horoscope } from '../../models/Horoscope';
import { SunSignService } from '../../sunSigns/sunsigns.service';
import { SunSigns_Master } from '../../models/SunSigns_Master';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'daily-horoscope',
    templateUrl: './daily.component.html'
})
export class DailyHoroscopeComponent {   
    horoscopetype: string = "daily"
    lang: string;
    

    constructor(private route: ActivatedRoute, private horoscopeService: HoroscopeService) {
        this.route.params.subscribe(params => {
            this.lang = params["lang"];
            this.horoscopeService.storeparams(this.horoscopetype, this.lang);
        });
    }
}
