import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FestivalService } from './festival.service';
import { Festival } from '../models/Festival';
import { formatDate } from '@angular/common';
import * as xlsx from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'festivals',
    templateUrl: 'festivals.component.html'
})
export class FestivalComponent implements OnInit {

    festivals: Festival[] = [];
    filteredFestivals: Festival[] = [];
    festival: Festival = new Festival();
    lstYear: number[] = [];
    lstMonth: any[] = [];
    filteryear: number;
    filtermonth: number;

    isExist: boolean = false;
    file: File;
    action: string = 'Add';
    isFileInvalid: boolean = true;
    isFilterChanged: boolean = false;

    //pagination
    currentPage: number = 1;
    itemsperpage: number = 12;
    startIndex: number = 0;
    lastIndex: number = 12;
    column: string = 'StartDate';
    isDesc: boolean = true;
    direction: number;


    @ViewChild("fileinput", { static: false }) public fileinput: ElementRef;
    @ViewChild("addForm", { static: false }) public modalform: any;
    @ViewChild("btnclose_festmodal", { static: false }) public modalpop: ElementRef;
    @ViewChild("btnclose_importmodal", { static: false }) public importmodalpop: ElementRef;
    @ViewChild("btnclose_deletemodal", { static: false }) public deletemodalpop: ElementRef;

    constructor(private festService: FestivalService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.addYears();
        this.getMonths();
        this.getAllFestivals();        
    }

    addYears() {
        for (var startYear = 1990; startYear <= new Date().getFullYear(); startYear++) {
            this.lstYear.push(startYear);
        }
    }

    getMonths() {
        this.lstMonth.push({ text: "January", value: 1 });
        this.lstMonth.push({ text: "February", value: 2 });
        this.lstMonth.push({ text: "March", value: 3 });
        this.lstMonth.push({ text: "April", value: 4 });
        this.lstMonth.push({ text: "May", value: 5 });
        this.lstMonth.push({ text: "June", value: 6 });
        this.lstMonth.push({ text: "July", value: 7 });
        this.lstMonth.push({ text: "August", value: 8 });
        this.lstMonth.push({ text: "September", value: 9 });
        this.lstMonth.push({ text: "October", value: 10 });
        this.lstMonth.push({ text: "November", value: 11 });
        this.lstMonth.push({ text: "December", value: 12 });
    }

    getAllFestivals() {
        this.spinner.show();
        this.festService.getAll().subscribe((fests: Festival[]) => {
            this.festivals = fests;
            this.spinner.hide();
            this.clearFilter();
        });
    }

    getFestival(id: number) {        
        this.festival = this.festivals.find(x => x.PKFestivalID == id);        
    }

    _isFestivalExist() {
        this.spinner.show();
        var _formatedDate = formatDate(this.festival.FestivalDate, "yyyy-MM-dd", 'en-US');
        this.festival.FestivalDate = new Date(_formatedDate);
        this.isFestivalExist();
        this.spinner.hide();
    }

    isFestivalExist() {
        this.festService.isFestivalExist(this.festival).subscribe((isExist: boolean) => {
            this.isExist = isExist;
        });
    }

   addFestival() {
       if (!this.isExist) {
           this.spinner.show();
           this.festService.insert(this.festival).subscribe((fests: Festival[]) => {
               this.festivals = fests;
               this.clearFilter();
               this.spinner.hide();
                this.modalpop.nativeElement.click();
            });
        }
    }
    updateFestival() {
        if (!this.isExist) {
            this.spinner.show();
            this.festService.update(this.festival).subscribe((fests: Festival[]) => {
                this.festivals = fests;
                this.clearFilter();
                this.spinner.hide();
                this.modalpop.nativeElement.click();
            });
        }
    }

    deleteFestival() {
        this.spinner.show();
        this.festService.delete(this.festival.PKFestivalID).subscribe((fests: Festival[]) => {
            this.festivals = fests;
            this.clearFilter();
            this.spinner.hide();
            this.deletemodalpop.nativeElement.click();
        });
    }

    FileChange(files: FileList) {
        var file = files[0];
        if (!file.name.match(/(\.xlsx|\.xls)$/)) {
            this.fileinput.nativeElement.value = "";
            this.isFileInvalid = true;
        }
        else {
            this.file = files[0];
            this.isFileInvalid = false;
        }
    }

    importdata() {
        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        reader.readAsBinaryString(this.file);
        reader.onload = (event) => {
            const data = reader.result;
            workBook = xlsx.read(data, { type: 'binary', cellDates: true, cellText: false, cellNF: false, dateNF: "MM-dd-yyyy" });
            jsonData = workBook.SheetNames.reduce((initial, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = xlsx.utils.sheet_to_json(sheet);
                initial[name].forEach((item, index) => {
                    var date = item["Date"];
                    if (item["Date"] != undefined) {
                        if (typeof (date) == "object") {
                            var userTimezoneOffset = date.getTimezoneOffset() * 60000;
                            item["Date"] = formatDate(new Date(date.getTime() - userTimezoneOffset).toLocaleDateString(), "dd-MM-yyyy", 'en-US');
                        }
                    }
                });
                return initial;
            }, {});

            this.spinner.show();
            this.festService.importdata(jsonData.Festivals).subscribe((fests: Festival[]) => {
                this.festivals = fests;
                this.clearFilter();
                this.spinner.hide();
                this.importmodalpop.nativeElement.click();
            });

            //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
            //this.setDownload(dataString);
        }




        //this.festService.importdata(this.file).subscribe((fests: Festival[]) => {
        //    this.festivals = fests;
        //    this.clearFilter();
        //    this.spinner.hide();
        //    this.importmodalpop.nativeElement.click();
        //});
    }


    resetform() {
        this.festival = new Festival();       
        this.modalform.reset();
        this.fileinput.nativeElement.value = "";
    }

    pageChanged(event: any) {
        this.startIndex = ((event.page - 1) * event.itemsPerPage);
        this.lastIndex = (event.page * event.itemsPerPage);
        this.filteredFestivals = this.festivals.slice(this.startIndex, this.lastIndex);
    }

    _filterByYear(festival: Festival) {
        var _filteryear = parseInt(formatDate(festival.FestivalDate, "yyyy", 'en-US'));
        return _filteryear == this.filteryear;
    }
    filterByYear() {
        this.isFilterChanged = true;
        this.filteredFestivals = this.festivals.filter(x => this._filterByYear(x));
    }

    _filterByMonth(festival: Festival) {
        var _filtermonth = parseInt(formatDate(festival.FestivalDate, "MM", 'en-US'));
        var _filteryear = parseInt(formatDate(festival.FestivalDate, "yyyy", 'en-US'));
        if (this.filteryear != null && this.filteryear != undefined && this.filteryear >= 1900)
            return (_filtermonth == this.filtermonth && _filteryear == this.filteryear)
        return _filtermonth == this.filtermonth;
    }
    filterByMonth() {
        this.isFilterChanged = true;
        this.filteredFestivals = this.festivals.filter(x => this._filterByMonth(x));
    }

    clearFilter() {
        this.isFilterChanged = false;
        this.startIndex = 0; this.lastIndex = 12;
        this.filteredFestivals = this.festivals.slice(this.startIndex, this.lastIndex);
    }

    sortby(property: any) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
    };

    groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };


    exportFestivals() {
        var lstexportjson: any[] = [];

        this.festivals.forEach((fest: Festival, key) => {                            
                lstexportjson.push(
                    {
                        "Date": formatDate(fest.FestivalDate, "dd-MM-yyyy", 'en-US'),
                        "Festival Name In English": fest.Festival_EN,
                        "Festival Name In Telugu": fest.Festival_TE,
                    });            
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(lstexportjson);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Festivals');
        xlsx.writeFile(wb, 'festivals.xlsx');
    
    }
}

