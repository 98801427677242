import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HoroscopeService } from '../horoscope.service';

@Component({
    selector: 'yearly-horoscope',
    templateUrl: './yearly.component.html'
})
export class YearlyHoroscopeComponent {
    horoscopetype: string = "yearly"
    lang: string;
    constructor(private route: ActivatedRoute, private horoscopeService: HoroscopeService) {
        this.route.params.subscribe(params => {
            this.lang = params["lang"];
            this.route.params.subscribe(params => {
                this.lang = params["lang"];
                this.horoscopeService.storeparams(this.horoscopetype, this.lang);
            });
        });
    }
}
