import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';
import { HoroscopeService } from './horoscope.service';
import { Horoscope } from './../models/Horoscope';
import { SunSignService } from './../sunSigns/sunsigns.service';
import { SunSigns_Master } from './../models/SunSigns_Master';
import { formatDate } from '@angular/common';

import * as xlsx from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'horoscope',
    templateUrl: './horoscope.component.html'
})
export class HoroscopeComponent implements OnInit {

    @Input() horoscopetype: string;
    @Input() lang: string;

    sunsigns: SunSigns_Master[] = [];
    horoscopes: Array<Horoscope> = [];
    filteredhoroscopes: Array<Horoscope> = [];

    horoscope: Horoscope = new Horoscope();
    lstHoroscopeItems: Horoscope[] = [];

    file: File;
    action: string = 'Add';
    isFileInvalid: boolean = true;
    isExist: boolean = false;
    currentPage: number = 1;
    itemsperpage: number = 12;
    startIndex: number = 0;
    lastIndex: number = 12;
    column: string = 'StartDate';
    isDesc: boolean = true;
    direction: number;
    isFilterChanged: boolean = false;
    filterstartdate: Date;
    filterenddate: Date;
    filteryear: number;
    horoscopeYear: number;
    lstYear: number[] = [];
    selectedHoroscope: Horoscope;

    @ViewChild("addForm", { static: false }) public modalform: any;
    @ViewChild("addmanyForm", { static: false }) public bulkmodalform: any;

    @ViewChild("btnclose_horosmodal", { static: false }) public modalpop: ElementRef;
    @ViewChild("btnclose_bulkhorosmodal", { static: false }) public bulkclosemodalpop: ElementRef;

    @ViewChild("btnclose_deletemodal", { static: false }) public deletemodalpop: ElementRef;

    @ViewChild("btnclose_importmodal", { static: false }) public importmodalpop: ElementRef;
    @ViewChild("fileinput", { static: false }) public fileinput: ElementRef;
    constructor(private horoscopeService: HoroscopeService, private sunsignService: SunSignService, private spinner: NgxSpinnerService, private router: Router, private route: ActivatedRoute) {
        this.route.params.subscribe(x => {

            this.horoscopetype = this.horoscopeService.horoscopequeryparams.value.horoscopetype;
            this.lang = this.horoscopeService.horoscopequeryparams.value.lang;

            this.getSunSigns();
            this.getHoroscopesbyType();
            this.initiateBulkHoroscope();
        });


    }

    ngOnInit() {
        this.addYears();
    }

    initiateBulkHoroscope() {
        var startitem = new Horoscope();
        startitem.FKSignId = 1;
        startitem.HoroscopeType = this.horoscopetype;
        startitem.HoroscopeLang = this.lang;
        this.lstHoroscopeItems.push(startitem);
    }

    getSunSigns() {
        this.sunsignService.getAllSunSigns().subscribe((signs: SunSigns_Master[]) => {
            this.sunsigns = signs;
        });
    }

    getAllHoroscopes() {
        this.horoscopeService.getAllhoroscopes().subscribe((hors: Horoscope[]) => {
            this.horoscopes = hors;
        });
    }

    getHoroscopesbyType() {
        this.spinner.show();
        this.horoscopeService.gethoroscopes(this.horoscopetype, this.lang).subscribe((hors: Horoscope[]) => {
            this.horoscopes = hors;
            this.spinner.hide();
            this.clearFilter();
        });
    }

    getHoroscope(id: number) {
        this.horoscope = this.horoscopes.find(x => x.PKHoroscopeId == id);
        if (this.horoscopetype == "yearly")
            this.horoscopeYear = parseInt(formatDate(this.horoscope.StartDate, "yyyy", 'en-US'));

        this.selectedHoroscope = this.horoscopes.find(x => x.PKHoroscopeId == id);
    }

    _isHoroscopeExist() {
        this.horoscope.FKSignId = this.lstHoroscopeItems[0].FKSignId;
        var _formatedDate = formatDate(this.horoscope.StartDate, "yyyy-MM-dd", 'en-US');
        this.horoscope.StartDate = new Date(_formatedDate);
        this.horoscope.HoroscopeType = this.horoscopetype;
        if (this.horoscopetype == "weekly") {
            this.horoscope.EndDate = new Date(_formatedDate);
            this.horoscope.EndDate.setDate(this.horoscope.StartDate.getDate() + 6);
        }
        this.isHoroscopeExist(null);
    }

    isHoroscopeExist(changetype: any) {
        this.spinner.show();
        var isValueChanged = false;

        this.horoscope.HoroscopeType = this.horoscopetype;
        this.horoscope.HoroscopeLang = this.lang;

        if (this.action == "Edit") {

            if (changetype != null && changetype == "year") {
                var actualyYear = parseInt(formatDate(this.selectedHoroscope.StartDate, "yyyy", 'en-US'));
                isValueChanged = this.horoscopeYear != actualyYear ? true : false;
            }

            if (changetype != null && changetype == "sign") {
                isValueChanged = this.selectedHoroscope.Sign.SignId != this.horoscope.FKSignId ? true : false;
            }
        } else if (this.action == "Add")
            isValueChanged = true;

        if (this.horoscopetype == "yearly") {
            this.horoscope.StartDate = new Date(`${this.horoscopeYear}-01-01T00:00:00.000Z`);
            this.horoscope.EndDate = new Date(`${this.horoscopeYear}-12-31T00:00:00.000Z`);
        }

        if (isValueChanged || changetype == null) {
            this.horoscopeService.isHoroscopeExist(this.horoscope).subscribe((isExist: boolean) => {
                this.isExist = isExist;
            });
        }

        this.spinner.hide();
    }

    addHoroscope() {
        if (!this.isExist) {
            this.spinner.show();
            if (this.horoscopetype == "yearly") {
                this.horoscope.StartDate = new Date(`${this.horoscopeYear}-01-01T00:00:00.000Z`);
                this.horoscope.EndDate = new Date(`${this.horoscopeYear}-12-31T00:00:00.000Z`);
            }
            this.horoscope.HoroscopeLang = this.lang;
            this.horoscopeService.insert(this.horoscope).subscribe((horoscopes: Horoscope[]) => {
                this.horoscopes = horoscopes;
                this.spinner.hide();
                this.clearFilter();
                this.modalpop.nativeElement.click();
            });
        }
    }


    addManyHoroscope() {
        if (!this.isExist) {
            this.spinner.show();

            this.lstHoroscopeItems[0].StartDate = this.horoscope.StartDate;
            if (this.horoscopetype == "weekly") {
                var _formatedDate = formatDate(this.horoscope.StartDate, "yyyy-MM-dd", 'en-US');
                this.lstHoroscopeItems[0].EndDate = new Date(_formatedDate);
                this.lstHoroscopeItems[0].EndDate.setDate(this.horoscope.StartDate.getDate() + 6);
            }
            if (this.lstHoroscopeItems.length == 12) {
                this.horoscopeService.insertbulk(this.lstHoroscopeItems).subscribe((horoscopes: Horoscope[]) => {
                    this.horoscopes = horoscopes;
                    this.spinner.hide();
                    this.clearFilter();
                    this.bulkclosemodalpop.nativeElement.click();
                });
            }
        }
    }

    addHoroscopeItem() {
        var lastitem = this.lstHoroscopeItems[(this.lstHoroscopeItems.length - 1)];
        var newitem = new Horoscope();
        newitem.FKSignId = lastitem.FKSignId + 1;
        newitem.HoroscopeType = this.horoscopetype;
        newitem.HoroscopeLang = this.lang;
        newitem.StartDate = this.horoscope.StartDate;

        if (this.horoscopetype == "weekly") {
            var _formatedDate = formatDate(this.horoscope.StartDate, "yyyy-MM-dd", 'en-US');
            newitem.EndDate = new Date(_formatedDate);
            newitem.EndDate.setDate(this.horoscope.StartDate.getDate() + 6);
        }
        this.lstHoroscopeItems.push(newitem);
    }

    updateHoroscope() {
        if (!this.isExist) {
            this.spinner.show();
            if (this.horoscopetype == "yearly") {
                this.horoscope.StartDate = new Date(`${this.horoscopeYear}-01-01T00:00:00.000Z`);
                this.horoscope.EndDate = new Date(`${this.horoscopeYear}-12-31T00:00:00.000Z`);
            }
            this.horoscopeService.update(this.horoscope).subscribe((horoscopes: Horoscope[]) => {
                this.horoscopes = horoscopes;
                this.spinner.hide();
                this.clearFilter();
                this.modalpop.nativeElement.click();
            });
        }
    }

    deleteHoroscope() {
        this.spinner.show();
        this.horoscopeService.delete(this.horoscope.PKHoroscopeId, this.horoscopetype).subscribe((horoscopes: Horoscope[]) => {
            this.horoscopes = horoscopes;
            this.spinner.hide();
            this.clearFilter();
            this.deletemodalpop.nativeElement.click();
        });
    }

    deleteByDate() {
        this.spinner.show();

        var fromdate = formatDate(this.filteredhoroscopes[0].StartDate, "MM-dd-yyyy", 'en-US');
        var todate = this.horoscopetype == "daily" ? null : formatDate(this.filteredhoroscopes[0].EndDate, "MM-dd-yyyy", 'en-US');
        this.horoscopeService.deleteByDate(fromdate, todate, this.horoscopetype, this.lang).subscribe((horoscopes: Horoscope[]) => {
            this.horoscopes = horoscopes;
            this.spinner.hide();
            this.clearFilter();
            this.deletemodalpop.nativeElement.click();
        });
    }

    FileChange(files: FileList) {
        var file = files[0];
        if (!file.name.match(/(\.xlsx|\.xls)$/)) {
            this.fileinput.nativeElement.value = "";
            this.isFileInvalid = true;
        }
        else {
            this.file = files[0];
            this.isFileInvalid = false;
        }
    }

    importdata() {


        let workBook = null;
        let jsonData = null;
        const reader = new FileReader();
        reader.readAsBinaryString(this.file);
        reader.onload = (event) => {
            const data = reader.result;
            workBook = xlsx.read(data, { type: 'binary', cellDates: true, cellText: false, cellNF: false, dateNF: "dd-MM-yyyy" });
            jsonData = workBook.SheetNames.reduce((initial, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = xlsx.utils.sheet_to_json(sheet);
                initial[name].forEach((item, index) => {
                    var date = item["Date"];
                    if (item["Date"] != undefined) {
                        if (typeof (date) == "object") {
                            var userTimezoneOffset = date.getTimezoneOffset() * 60000;
                            item["Date"] = formatDate(new Date(date.getTime() - userTimezoneOffset).toLocaleDateString(), "dd-MM-yyyy", 'en-US');
                        }
                    }
                    else {
                        var fromdate = item["From Date"];
                        var todate = item["To Date"];
                        if (typeof (fromdate) == "object" && typeof (todate) == "object") {
                            var userTimezoneOffset = fromdate.getTimezoneOffset() * 60000;
                            item["From Date"] = formatDate(new Date(fromdate.getTime() - userTimezoneOffset).toLocaleDateString(), "dd-MM-yyyy", 'en-US');
                            item["To Date"] = formatDate(new Date(todate.getTime() - userTimezoneOffset).toLocaleDateString(), "dd-MM-yyyy", 'en-US');
                        }
                    }

                });

                return initial;
            }, {});

            this.spinner.show();
            var lstHoroscope = this.horoscopetype == "daily" ? jsonData.Daily : this.horoscopetype == "weekly" ? jsonData.Weekly : jsonData.Yearly;
            this.horoscopeService.importdata(lstHoroscope, this.horoscopetype, this.lang).subscribe((horoscopes: Horoscope[]) => {
                this.horoscopes = horoscopes;
                this.spinner.hide();
                this.clearFilter();
                this.importmodalpop.nativeElement.click();
            });

            //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
            //this.setDownload(dataString);
        }



        //let fileReader = new FileReader();
        //fileReader.readAsArrayBuffer(this.file);
        //fileReader.onload = (e) => {
        //    var arrayBuffer = fileReader.result;
        //    var data =  new Uint8Array(arrayBuffer);
        //    var arr = new Array();
        //    for (var i = 0; i != data.length; ++i)
        //        arr[i] = String.fromCharCode(data[i]);
        //    var bstr = arr.join("");
        //    var workbook = xlsx.read(bstr, { type: "binary" });
        //    var first_sheet_name = workbook.SheetNames[0];
        //    var worksheet = workbook.Sheets[first_sheet_name];
        //    console.log(xlsx.utils.sheet_to_json(worksheet, { raw: true }));
        //    var arraylist = xlsx.utils.sheet_to_json(worksheet, { raw: true });
        //    var filelist = [];
        //    console.log(filelist);
        //}



    }


    resetform() {
        this.horoscope = new Horoscope();
        this.lstHoroscopeItems = [];
        this.initiateBulkHoroscope();
        this.modalform.reset();
        this.bulkmodalform.reset();
        this.fileinput.nativeElement.value = "";
    }

    pageChanged(event: any) {
        this.startIndex = ((event.page - 1) * event.itemsPerPage);
        this.lastIndex = (event.page * event.itemsPerPage);
        this.filteredhoroscopes = this.horoscopes.slice(this.startIndex, this.lastIndex);
    }

    addYears() {
        for (var startYear = 1990; startYear <= new Date().getFullYear() + 30; startYear++) {
            this.lstYear.push(startYear);
        }
    }

    _filterByDate(horoscope: Horoscope, startDate: any, endDate: any) {

        if (endDate == null) {
            var _startdate = formatDate(horoscope.StartDate, "dd/MM/yyyy", 'en-US');
            var _compareStartDate = formatDate(startDate, "dd/MM/yyyy", 'en-US');
            return _startdate == _compareStartDate;
        }
        if (startDate == null) {
            var _enddate = formatDate(horoscope.EndDate, "dd/MM/yyyy", 'en-US');
            var _compareendDate = formatDate(endDate, "dd/MM/yyyy", 'en-US');
            return _enddate == _compareendDate;
        }
    }

    filterBydate(startDate: any, endDate: any) {
        this.isFilterChanged = true;
        if (startDate == null) {
            this.filteredhoroscopes = this.horoscopes.filter(x => this._filterByDate(x, null, endDate));
        }
        if (endDate == null) {
            this.filteredhoroscopes = this.horoscopes.filter(x => this._filterByDate(x, startDate, null));
        }
    }

    _filterByYear(horoscope: Horoscope) {
        var _filteryear = parseInt(formatDate(horoscope.StartDate, "yyyy", 'en-US'));
        return _filteryear == this.filteryear;
    }
    filterByYear() {
        this.isFilterChanged = true;
        this.filteredhoroscopes = this.horoscopes.filter(x => this._filterByYear(x));
    }

    clearFilter() {
        this.isFilterChanged = false;
        this.startIndex = 0; this.lastIndex = 12;
        this.filteredhoroscopes = this.horoscopes.slice(this.startIndex, this.lastIndex);
    }

    sortby(property: any) {
        this.isDesc = !this.isDesc; //change the direction    
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
    };

    groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };


    exportDailyHoroscope() {

        var lstexportjson: any[] = [];
        var result = this.groupBy(this.horoscopes, "StartDate");
        Object.keys(result).forEach((groupkey, groupindex) => {
            if (result[groupkey].length == 12) {
                var lstHoroscopes = result[groupkey];
                lstexportjson.push(
                    {
                        "Date": formatDate(lstHoroscopes[0].StartDate, "dd-MM-yyyy", 'en-US'),
                        "Aries": lstHoroscopes[0].HoroscopeText,
                        "Taurus": lstHoroscopes[1].HoroscopeText,
                        "Gemini": lstHoroscopes[2].HoroscopeText,
                        "Cancer": lstHoroscopes[3].HoroscopeText,
                        "Leo": lstHoroscopes[4].HoroscopeText,
                        "Virgo": lstHoroscopes[5].HoroscopeText,
                        "Libra": lstHoroscopes[6].HoroscopeText,
                        "Scorpio": lstHoroscopes[7].HoroscopeText,
                        "Sagittarius": lstHoroscopes[8].HoroscopeText,
                        "Capricorn": lstHoroscopes[9].HoroscopeText,
                        "Aquarius": lstHoroscopes[10].HoroscopeText,
                        "Pisces": lstHoroscopes[11].HoroscopeText,
                    });
            }
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(lstexportjson);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Daily');
        xlsx.writeFile(wb, 'dailyhoroscopes.xlsx');
    }

    exportWeeklyHoroscope() {
        var lstexportjson: any[] = [];
        var result = this.groupBy(this.horoscopes, "StartDate");
        Object.keys(result).forEach((groupkey, groupindex) => {
            if (result[groupkey].length == 12) {
                var lstHoroscopes = result[groupkey];
                lstexportjson.push(
                    {
                        "From Date": formatDate(lstHoroscopes[0].StartDate, "dd-MM-yyyy", 'en-US'),
                        "To Date": formatDate(lstHoroscopes[0].EndDate, "dd-MM-yyyy", 'en-US'),
                        "Aries": lstHoroscopes[0].HoroscopeText,
                        "Taurus": lstHoroscopes[1].HoroscopeText,
                        "Gemini": lstHoroscopes[2].HoroscopeText,
                        "Cancer": lstHoroscopes[3].HoroscopeText,
                        "Leo": lstHoroscopes[4].HoroscopeText,
                        "Virgo": lstHoroscopes[5].HoroscopeText,
                        "Libra": lstHoroscopes[6].HoroscopeText,
                        "Scorpio": lstHoroscopes[7].HoroscopeText,
                        "Sagittarius": lstHoroscopes[8].HoroscopeText,
                        "Capricorn": lstHoroscopes[9].HoroscopeText,
                        "Aquarius": lstHoroscopes[10].HoroscopeText,
                        "Pisces": lstHoroscopes[11].HoroscopeText,
                    });
            }
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(lstexportjson);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Weekly');
        xlsx.writeFile(wb, 'weeklyhoroscopes.xlsx');
    }

    exportYearlyHoroscope() {
        var lstexportjson: any[] = [];
        var result = this.groupBy(this.horoscopes, "StartDate");
        Object.keys(result).forEach((groupkey, groupindex) => {
            if (result[groupkey].length == 12) {
                var lstHoroscopes = [];
                lstHoroscopes = result[groupkey];

                lstHoroscopes.forEach((horoscope: Horoscope, rowIndex) => {
                    lstexportjson.push(
                        {
                            "From Date": formatDate(horoscope.StartDate, "dd-MM-yyyy", 'en-US'),
                            "To Date": formatDate(horoscope.EndDate, "dd-MM-yyyy", 'en-US'),
                            "Sign": horoscope.Sign.SignName_EN,
                            "Horoscope": horoscope.HoroscopeText,
                            "adayam": horoscope.Adhayam,
                            "vyayam": horoscope.Vyayam,
                            "raja pujyam": horoscope.RajaPujyam,
                            "avamanam": horoscope.Avamanam,
                            "kandhaya phalam 1": horoscope.KhandayaPhalam1,
                            "kandhaya phalam 2": horoscope.KhandayaPhalam2,
                            "kandhaya phalam 3": horoscope.KhandayaPhalam3,
                            "kandhaya phalam 4": horoscope.KhandayaPhalam4,
                            "Description": horoscope.Description
                        });
                });
            }
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(lstexportjson);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Yearly');
        xlsx.writeFile(wb, 'yearlyhoroscopes.xlsx');
    }
}
