import { Component,Input} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HoroscopeService } from '../horoscope.service';

@Component({
    selector: 'weekly-horoscope',
    templateUrl: './weekly.component.html'
})
export class WeeklyHoroscopeComponent {
    horoscopetype: string = "weekly";
    lang: string;
    constructor(private route: ActivatedRoute, private horoscopeService: HoroscopeService) {
        this.route.params.subscribe(params => {
            this.lang = params["lang"];
            this.route.params.subscribe(params => {
                this.lang = params["lang"];
                this.horoscopeService.storeparams(this.horoscopetype, this.lang);
            });
        });
    }
}
