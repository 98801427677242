import { Component } from '@angular/core';
import { AuthenticateService } from '../../login/authenticate.service';

@Component({
    selector: 'admin-home',
    templateUrl: './adminhome.component.html',
})
export class AdminHomeComponent {
    constructor(private authService: AuthenticateService) {
        this.authService.GetLoggedInUser().subscribe(x => {

        });

    }
}
