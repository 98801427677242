import { SunSigns_Master } from "./SunSigns_Master";

export class Horoscope {
    public PKHoroscopeId: number;
    public HoroscopeType: string;
    public HoroscopeLang: string;
    public FKSignId: number;
    public StartDate: Date;
    public EndDate: Date;
    public HoroscopeText: string;
    public Description: string;
    public Adhayam:number;
    public Vyayam: number;
    public RajaPujyam:number;
    public Avamanam: number;
    public KhandayaPhalam1: number;
    public KhandayaPhalam2: number;
    public KhandayaPhalam3: number;
    public KhandayaPhalam4: number;


    public CreatedBy: number;
    public ModifiedBy: number;
    public CreatedDate: Date;
    public ModifiedDate: Date;
    public IsActive: boolean;

    public Sign: SunSigns_Master;
     
}
