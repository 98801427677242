import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';


import { AdminHomeComponent } from './home/adminhome.component';
import { SunSignsComponent } from '../sunSigns/sunsigns.component';
import { DailyHoroscopeComponent } from '../horoscope/daily/dailyhoroscope.component';
import { WeeklyHoroscopeComponent } from '../horoscope/weekly/weekly.component';
import { HoroscopeComponent } from '../horoscope/horoscope.component';
import { YearlyHoroscopeComponent } from '../horoscope/yearly/yearly.component';
import { OrderrByPipe } from '../pipes/order.pipe';
import { StartDateValidator } from '../horoscope/weekly/startdate.validation';
import { FestivalComponent } from '../festivals/festivals.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { LoginComponent } from '../login/login.component';
import { SharedModule } from '../shared.module';
import { AdminGuard } from '../login/admin.guard';


@NgModule({
    declarations: [
        AdminHomeComponent,                
        HoroscopeComponent,
        DailyHoroscopeComponent,
        WeeklyHoroscopeComponent,
        YearlyHoroscopeComponent,
        FestivalComponent,        
    ],
    imports: [
        CommonModule,
        SharedModule,
        HttpClientModule,
        FormsModule,
       
        RouterModule.forChild([
            { path: '', redirectTo:'login', pathMatch: 'full' },
            { path: 'login', component: AdminHomeComponent },
            { path: 'signs', component: SunSignsComponent, canActivate: [AdminGuard]  },
            { path: 'dailyhoroscope/:lang', component: DailyHoroscopeComponent, canActivate: [AdminGuard] },
            { path: 'weeklyhoroscope/:lang', component: WeeklyHoroscopeComponent, canActivate: [AdminGuard] },
            { path: 'yearlyhoroscope/:lang', component: YearlyHoroscopeComponent, canActivate: [AdminGuard] },
            { path: 'festivals', component: FestivalComponent, canActivate: [AdminGuard] }
        ])
    ],
    providers: [

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AdminHomeComponent]
})
export class AdminModule { }
